import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Trbm from "@tightrope/redbmimic"

const homepageData = import("./data.json");

export default function test() {
  return(
    <HomepageLayout>
      <Helmet><title>Breaking News Tab - breakingnewstab.com</title>
      <style type="text/css">{`
      `}</style>
      </Helmet>
      <Trbm data={homepageData}></Trbm>
    </HomepageLayout>
  )
}
